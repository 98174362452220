import React from "react"

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid, Box, Container, Typography } from '@material-ui/core';


import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { TwitterTimelineEmbed } from 'react-twitter-embed';

import Home from "../components/home"
import About from "../components/about"
import Homeface from "../images/facebook/home.jpg";

import Globalbg from "../components/globalbg"

import styled from "styled-components";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Bline = styled.hr`
width:100%;
display: inline-block;
border-color: rgba(220, 220, 220, 0.125);
border-width: thin;
`


const IndexPage = () => (
  <Layout>
     <SEO title="Venture Capital - Private Equity - Advisory | Bharathvarsha" description="Bharathvarsha Ventures help and nurture the entrepreneurs of this sacred land - Bharathvarsha. We also nurture more successful enterprising companies with financial solutions" image={Homeface} pathname="/"  />
    <ThemeProvider theme={theme}>
    <Container>
          <Box pb={5} pt={5}>
        <Grid container>

        <Grid item sm={12}>
        <ScrollAnimation animateIn='zoomIn'>
        <Typography variant="h2" component="h1" gutterBottom align="center">
        We are here to fund your <br/><span style={{ fontStyle:'oblique' }}>smart</span> Idea
        </Typography>
        </ScrollAnimation>
        </Grid>
        <Grid item sm={3}></Grid>
        <Grid item xs={12} sm={6}>
        <ScrollAnimation animateIn='zoomIn'>
        {/* <div style={{ width:700, height:'auto', margin:'auto' }}> */}
          <Home/>
        {/* </div> */}
        </ScrollAnimation>
        </Grid>
        <Grid item sm={3}></Grid>


        </Grid>
        </Box>
        </Container>
        {/* </div> */}
  

        <div class="top_corner_body1"></div>

      <section id="aboutus">
      <div style={{ backgroundColor:'#4c5c96'}}>
        <Container>
          <Box pt={5} pb={5} mt={5}>
          <Grid container style={{color:'#fff'}}>
          <Grid item sm={12}>
          <ScrollAnimation animateIn='zoomIn'>
          <Typography variant="h2" component="h1" gutterBottom style={{color:'#fff'}}  align="center">
              ABOUT US
            </Typography>
            <Box mb={2}>
            <Bline/>
            </Box>
            </ScrollAnimation>
            </Grid>
          
            <Grid item sm={8}>
              <Box pr={2}>
              <ScrollAnimation animateIn='fadeInLeft'>
            <Typography variant="body1" paragraph>
            We at Bharathvarsha Ventures help and nurture the entrepreneurs of this sacred land - Bharathvarsha. We also nurture more successful enterprising companies with financial solutions which will propel their businesses to higher level of growth.
          
            The Management team is well experienced with more than 20 years of global experience in Alternative Investment platforms.
            
            We provide intelligent solutions for Capital Raising & Advisory Services in Private Equity, Venture Capital, Angel Funding, Family Offices verticals.
            </Typography>
            <Typography variant="body1" paragraph>          
We support both tech start-ups and tech-enabled traditional companies in raising capital from Private Equity and Venture Capital companies. We support angel-funding level to more early stage funding to up-to Private Equity funding stages.

In addition, we have strong relationship with Private Equity companies to deploy their capital efficiently in growth-focussed companies with a strong financial performance. We represent Private Companies with mandates to source deals for them.

We are also Venture Partner to Ah Ventures Pvt Ltd, Mumbai, a platform with 3000+ angel and venture capital investors.
</Typography>
<Typography variant="body1" paragraph>
Our investment services are sector agnostic. We also welcome and support traditional manufacturing and service companies which are tech enabled.

We have deep relationship with our existing investors and work closely with them to conclude transactions efficiently. We also welcome new investors who would be keen to participate in our start-ups funding platform. Our start-up funding platform provide curated deals which went through 3 levels of due diligence.
  </Typography> 
  </ScrollAnimation> 
  </Box>        
            </Grid>

            <Grid item xs={12} sm={4}>
            <ScrollAnimation animateIn='fadeInRight'>
            <Box pl={2}>
            <About/>
            </Box>
            </ScrollAnimation>
            </Grid>

          </Grid>
          </Box>
        </Container>
        </div>
       
        </section>
        {/* <div style={{ backgroundColor:'#4c5c96'}}>
        <div class="top_corner_body2">	&nbsp;	&nbsp; <br/>&nbsp; </div>
        </div> */}
        

{/* <section id="bill">
<div style={{ backgroundColor:'#fdca4d'}}>
  <Container>
    <Box pt={10} pb={8}>
    <Grid container>
      <Grid sm={3}></Grid>
      <Grid item sm={6} align="center">
      <ScrollAnimation animateIn='bounceIn'>
      <Typography variant="h2" component="h2" gutterBottom >
      Bill-Discounting
      </Typography>
      <Box mb={2}>
      <Bline/>
      </Box>
      </ScrollAnimation>
      <ScrollAnimation animateIn='lightSpeedIn'>
      <Typography variant="body1" paragraph align="center">
      We also arrange bill-discounting facility for MSMEs without collateral to enable easy availability of working capital for their business.
      </Typography>
      <Typography variant="body1" paragraph align="center">
This facility is offered to clients in association with Credable, leading fintech company in supply chain financing. (https://www.credable.in)
      </Typography>
      </ScrollAnimation>
            
      </Grid>
      <Grid sm={3}></Grid>
    

    </Grid>
    </Box>
  </Container>
  </div>
  </section> */}

        {/* <div style={{ backgroundColor:'#fdca4d'}}>
        <div class="top_corner_body2">	&nbsp;	&nbsp; <br/>&nbsp; </div>
        </div> */}

  <section id="finance">

  <Container>
    <Box pt={15} pb={10}>
    <Grid container>
    <Grid sm={3}></Grid>
      <Grid item sm={6}>
      <ScrollAnimation animateIn='zoomIn'>
      <Typography variant="h2" component="h2" gutterBottom align='center'>
      Financial Tools
      </Typography>
      <Box mb={2}>
      <Bline/>
      </Box>
      </ScrollAnimation>
      <ScrollAnimation animateIn='slideInUp'>
      <Typography variant="body1" paragraph align='center'>
      We provide ready-made easy-to-use financial tools for start-ups to prepare pitch-deck and financial models for preparing future financial statements and analysis. These tools are provided to our clients in association with Ah Ventures Pvt Ltd, Mumbai
      </Typography>
      </ScrollAnimation>
            
      </Grid>
      <Grid sm={3}></Grid>

    </Grid>
    </Box>
  </Container>
  </section>

<section id="global">
  <Globalbg/>
</section>

<section id="contact">
<div style={{ backgroundColor:'#fdca4d'}}>
  <Container>
    <Box pt={10} pb={8}>
    <Grid container>
    <Grid item xs={12}>
    <ScrollAnimation animateIn='flash'>
    <Typography component="h2" variant="h2" gutterBottom align="center">
      Contact
      </Typography>
      <Box mb={2}>
      <Bline/>
      </Box>
      </ScrollAnimation>
    </Grid>
      <Grid item xs={12} sm={6} md={8} align="center" style={{ color:'#000'}}>
        <Box pt={3}>
      <ScrollAnimation animateIn='fadeInLeft'>
     <Typography variant="h4" paragraph align="center">
     Bharathvarsha Ventures
     </Typography>
     <Typography variant="body1" align="center" paragraph>
Workenstein (Workafella),<br/>
Room No: 725, 
Level 7, IIFL Towers, <br/>
143, MGR Main Road, Kandanchavadi, <br/>
 Perungudi, Chennai- 600096. India.

     </Typography>

     <Typography variant="h6">
     <EmailIcon style={{ position:"relative", top:7, color:'#c13b2c' }}/> <a href="mailto:info@bharathvarsha.in" rel="noopener noreferrer" target="_blank" style={{ color:'#000'}}> info@bharathvarsha.in</a>
     </Typography>
     <Typography variant="h6">
     <a href="https://www.linkedin.com/in/bharathvarshaventures/" rel="noopener noreferrer" target="_blank" style={{ color:'#000'}}><LinkedInIcon  style={{ position:"relative", top:7, color:'#0e76a8' }}/> Linkedin</a>
     </Typography>
          </ScrollAnimation> 
          </Box> 
      </Grid>
      <Grid xs={12} sm={6} md={4}>
      <ScrollAnimation animateIn='fadeInRight'>
      <TwitterTimelineEmbed
  sourceType="profile"
  screenName="BharathvarshaV"
  options={{height: 400}}
      />
      </ScrollAnimation>
      </Grid>
    

    </Grid>
    </Box>
  </Container>
  </div>
  </section>

 </ThemeProvider>
  </Layout>
)

export default IndexPage


