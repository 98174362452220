import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import BackgroundImage from 'gatsby-background-image'

import styled from "styled-components";
const Bline = styled.hr`
width:100%;
display: inline-block;
border-color: rgba(220, 220, 220, 0.125);
border-width: thin;
`

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
        
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
        >
<div style={{ backgroundColor:'rgba(0, 0, 0, 0.52)'}}>
<Container>
  <Box pt={15} pb={15}>
  <Grid container>
    <Grid item sm={6} style={{ color:'#fff'}}>
    <ScrollAnimation animateIn='flash'>
    <Typography variant="h2" component="h2" gutterBottom style={{ color:'#fff'}}>
    Global Reach
    </Typography>
    <Box mb={2}>
    <Bline/>
    </Box>
    </ScrollAnimation>
    <ScrollAnimation animateIn='zoomIn'>
    <Typography variant="body1" paragraph>
    We are based in India, Singapore, Hong Kong and USA.
    </Typography>
          </ScrollAnimation>
    </Grid>
    <Grid sm={6}></Grid>

  </Grid>
  </Box>
</Container>
</div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
width: 100%;
background-position: bottom center;
background-size: cover;
background-attachment: fixed;
`

export default StyledBackgroundSection